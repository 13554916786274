"use client";

import { Dispatch, SetStateAction, createContext } from "react";

interface SearchContextProps {
  searchQuery: SearchQueryProps;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setSearchQuery: Dispatch<SetStateAction<SearchQueryProps>>;
  gameData: GameData;
  setGameData: Dispatch<SetStateAction<GameData>>;
}

export const initialSearchQuery = {
  limit: 12,
  searchTerm: "",
  tags: "",
  latitude: 0,
  longitude: 0,
  maxDistance: 0,
};

export const searchContext = createContext<SearchContextProps>({
  searchQuery: initialSearchQuery,
  page: 1,
  setPage: () => {},
  setSearchQuery: () => {},
  gameData: {
    total: 0,
    gameList: [],
  },
  setGameData: () => {},
});
