"use client";

import { ReactNode, useState } from "react";
import { initialSearchQuery, searchContext } from "./context";

export interface SearchContextProviderProps {
  children: ReactNode;
}

export const SearchContextProvider = ({
  children,
}: SearchContextProviderProps) => {
  const [searchQuery, setSearchQuery] =
    useState<SearchQueryProps>(initialSearchQuery);
  const [gameData, setGameData] = useState<GameData>({
    total: 0,
    gameList: [],
  });
  const [page, setPage] = useState(1);

  return (
    <searchContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        gameData,
        setGameData,
        page,
        setPage,
      }}
    >
      {children}
    </searchContext.Provider>
  );
};
